<template>
  <div class="main" data-title="客户跟踪" v-title>
    <div class="btn-sticky">
      <span @click="showCheck">检核</span>
    </div>
    <trace-detail ref="trace"></trace-detail>
    <!-- 检核信息 -->
    <van-popup
      class="round-popup"
      position="bottom"
      style="height: 50%; width: 100%;"
      v-model="popupVisible"
    >
      <div class="cell-group">
        <van-field
          autosize
          label="检核意见"
          placeholder="请输入"
          required
          rows="2"
          type="textarea"
          v-model="form.check_memo"
        />
      </div>
      <div class="btn">
        <van-button @click="onSubmit" block round type="info">提交</van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import TraceDetail from '@/components/TraceDetail.vue'
export default {
  components: {
    TraceDetail
  },
  data() {
    return {
      popupVisible: false,
      form: {
        trace_id: this.$route.query.trace_id,
        check_memo: ''
      }
    }
  },
  mounted() {
    this.$refs.trace.show(this.$route.query.trace_id)
  },
  methods: {
    showCheck() {
      this.popupVisible = true
    },
    closePopup() {
      this.form.check_memo = ''
      this.popupVisible = false
    },
    onSubmit() {
      if(this.form.check_memo == ''){
        this.$notify({ type: 'danger', message: '检核内容没有填写' })
        return false
      }


      this.$axios.post('/trace/check', this.form).then(res => {
        if (res.data.code == 200) {
          this.$notify({ type: 'success', message: res.data.msg })
          this.isCheck = 1
          this.closePopup()
        } else {
          this.$notify({ type: 'danger', message: res.data.msg })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.btn-sticky {
  display: flex;
  position: fixed;
  padding: 10px 20px;
  right: 0px;
  color: #1989fa;
  z-index: 999;
}
.btn{
  padding: 0 20px;
}
</style>